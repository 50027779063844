<button class="{ type }" type="button" on:click><slot></slot></button>

<script lang="ts">export let type = 'default';
</script>

<style lang="scss">button {
  --button-color: var(--color-bg);
  --text-color: var(--color-fg);
  --transition: var(--default-animation-duration) linear;
  padding: 1em;
  border-radius: var(--default-border-radius);
  border-width: 0.25em;
  border-color: var(--button-color);
  background-color: var(--button-color);
  color: var(--text-color);
  cursor: pointer;
}
button.primary {
  --button-color: var(--color-theme);
}
button.accent {
  --button-color: var(--color-accent);
}
button.success, button.danger {
  --text-color: var(--color-lightest);
}
button.success {
  --button-color: var(--color-success);
}
button.warn {
  --button-color: var(--color-warn);
  --text-color: var(--color-darkest);
}
button.danger {
  --button-color: var(--color-danger);
}</style>
