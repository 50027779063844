<DefaultLayout><article>{#await regionPromise}<p>Loading...</p>{:then region}<h1>The { getLocalizedName(region.names, $userLanguage) } Region</h1>{:catch error}<p>{ error.message }</p>{/await}
{#await locationPromise}<p>Loading...</p>{:then location}<Level align="space-between"><h2>Location: { getLocalizedName(location.names, $userLanguage) }</h2><div class="button-container"><IconButton on:click="{ displayFilterControls }"><Funnel></Funnel></IconButton></div></Level><LocationAreaListComponent { locationAreaNames }></LocationAreaListComponent>{:catch error}<p>{ error.message }</p>{/await}</article></DefaultLayout>

<script lang="ts">import DefaultLayout from '~/layouts/default.layout.svelte';
import EncounterFilterControl from '~/components/encounter-filter-control.component.svelte';
import Funnel from 'phosphor-svelte/lib/Funnel';
import { getLocalizedName } from '~/utilities/pokeapi/get-localized-name.utility';
import IconButton from '~/components/form/icon-button.component.svelte';
import Level from '~/components/layout/level.component.svelte';
import LocationAreaListComponent from '~/components/location-area-list.component.svelte';
import locationRepository from '~/services/repositories/location.repository';
import regionRepository from '~/services/repositories/region.repository';
import { uiStore } from '~/stores/ui.store';
import { userStore } from '~/stores/user.store';
import { onDestroy, onMount } from 'svelte';
// Props
export let regionName;
export let locationName;
// Stored Values
const { user: { language: userLanguage } } = userStore;
// Data
const regionPromise = regionRepository.get(regionName);
const locationPromise = locationRepository.get(locationName);
let locationAreaNames = [];
// Methods
const displayFilterControls = () => {
    uiStore.displayModal(EncounterFilterControl);
};
// Lifecycle Hooks
onMount(async () => {
    const location = await locationPromise;
    userStore.user.currentLocation.set(location);
    locationAreaNames = location.areas.map(({ name }) => name);
});
onDestroy(() => {
    userStore.user.currentLocation.set(null);
});
</script>

<style lang="scss">div.button-container {
  font-size: 2em;
}</style>
