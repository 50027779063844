<Card><div class="pokemon-entry-container" use:inview="{ {} }" on:change="{ inviewHandler }">{#if loading}<p class="loading">Loading...</p>{:else}<div class="heading"><span class="entry-number">#{ pokemonEntry.entry_number }</span><span class="name">{ pokemonSpeciesDisplayName }</span><span class="spacer"></span></div><div class="sprite-container"><img src="{ pokemon.sprites.front_default }" alt="{ pokemonSpeciesDisplayName } "></div>{/if}</div></Card>

<script lang="ts">import Card from '~/components/layout/card.component.svelte';
import { dashToCaps } from '~/utilities/string.utilities';
import debounce from 'debounce';
import { getLocalizedName } from '~/utilities/pokeapi/get-localized-name.utility';
import { inview } from 'svelte-inview/dist/index.mjs';
import pokemonRepository from '~/services/repositories/pokemon.repository';
import pokemonSpeciesRepository from '~/services/repositories/pokemon-species.repository';
import { time } from '~/utilities/time.utility';
import { userStore } from '~/stores/user.store';
// Props
export let pokemonEntry;
// Store Data
const { user: { language: userLanguage } } = userStore;
// Data
let loading = true;
let isInView = false;
let pokemon;
let pokemonSpecies;
let pokemonSpeciesDisplayName = '';
// Methods
const inviewHandler = debounce(({ detail: { inView } }) => {
    if (inView)
        isInView = inView;
}, time(200).milliseconds);
const onInView = async () => {
    pokemonSpecies = await pokemonSpeciesRepository.get(pokemonEntry.pokemon_species.name);
    const defaultVariety = pokemonSpecies
        .varieties
        .filter(({ is_default }) => is_default)
        .reduce(variety => variety);
    pokemon = await pokemonRepository.get(defaultVariety.pokemon.name);
    loading = false;
};
// Reactive Hooks
$: if (isInView)
    onInView();
$: if (pokemonSpecies)
    pokemonSpeciesDisplayName = getLocalizedName(pokemonSpecies.names, $userLanguage) || dashToCaps(pokemonSpecies.name);
</script>

<style lang="scss">div.pokemon-entry-container {
  min-height: 16rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
div.pokemon-entry-container p.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}
div.pokemon-entry-container div.heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
}
div.pokemon-entry-container div.heading span.entry-number {
  font-style: italic;
  font-size: 0.9em;
}
div.pokemon-entry-container div.heading span.name {
  font-size: 1.2em;
}
div.pokemon-entry-container div.sprite-container {
  text-align: center;
}</style>
