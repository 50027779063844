<DefaultLayout><h1>Collection</h1><div class="summary-stats"><h2>Quick Stats</h2><p>You've caught&nbsp;<span class="collection-count">{ thisVersionPokemon.length }</span>&nbsp;Pokémon in this game.</p><p>You've caught&nbsp;<span class="collection-count">{ $caughtPokemon.length }</span>&nbsp;Pokémon in all games.</p></div><div class="collection"><h2>Pokémon { versionDisplayName }</h2><ul class="pokemon-list">{#each thisVersionPokemon as caughtPokemon (caughtPokemon.caught) }<li out:slide="{ {} }"><CaughtPokemonSummary { caughtPokemon } includeVersion="{ false }"></CaughtPokemonSummary></li>{/each}</ul></div><div class="collection"><h2>Other Versions</h2><ul class="pokemon-list">{#each otherVersionPokemon as caughtPokemon (caughtPokemon.caught) }<li out:slide="{ {} }"><CaughtPokemonSummary { caughtPokemon }></CaughtPokemonSummary></li>{/each}</ul></div></DefaultLayout>

<script lang="ts">import CaughtPokemonSummary from '~/components/caught-pokemon-summary.component.svelte';
import { dashToCaps } from '~/utilities/string.utilities';
import DefaultLayout from '~/layouts/default.layout.svelte';
import { getLocalizedName } from '~/utilities/pokeapi/get-localized-name.utility';
import { onMount } from 'svelte';
import { slide } from 'svelte/transition';
import { userStore } from '~/stores/user.store';
import versionRepository from '~/services/repositories/version.repository';
// Store Data
const { user: { language: userLanguage, pokemon: caughtPokemon, version: userVersion } } = userStore;
// Data
let thisVersionPokemon = [];
let otherVersionPokemon = [];
let version = null;
let versionDisplayName = '';
// Methods
const populateVersion = async (name) => {
    version = await versionRepository.get(name);
};
// Reactive Data
$: thisVersionPokemon = $caughtPokemon.filter(({ version }) => version === $userVersion.name);
$: otherVersionPokemon = $caughtPokemon.filter(({ version }) => version !== $userVersion.name);
$: populateVersion($userVersion.name);
$: if (version)
    versionDisplayName = getLocalizedName(version.names, $userLanguage) || dashToCaps(version.name);
</script>

<style lang="scss">div.summary-stats span.collection-count {
  font-size: 1.3em;
  font-style: italic;
  padding: 0 0.25em;
  border-bottom: 0.0625em solid currentColor;
}

div.collection {
  margin-bottom: 3rem;
}
div.collection:last-of-type {
  margin-bottom: 0;
}
div.collection ul.pokemon-list {
  list-style-type: none;
  padding: 0;
}
div.collection ul.pokemon-list li {
  margin-bottom: 1rem;
}
div.collection ul.pokemon-list li:last-of-type {
  margin-bottom: 0;
}</style>
