<div class="level" use:styles="{ levelStyles }"><slot></slot></div>

<script lang="ts">import { styles } from '~/utilities/actions/styles.action';
// Props
export let align = 'flex-start';
// Reactive Data
let levelStyles = {};
$: {
    levelStyles = { align };
}
</script>

<style lang="scss">div.level {
  display: flex;
  justify-content: var(--align);
  align-items: center;
}</style>
