<Card><div class="region-summary-container"><img src="/images/regions/{ region.name }.png" alt="{ displayName } Region Map"><div class="details"><p class="name">{ displayName }</p></div></div></Card>

<script lang="ts">import Card from '~/components/layout/card.component.svelte';
import { dashToCaps } from '~/utilities/string.utilities';
import { getLocalizedName } from '~/utilities/pokeapi/get-localized-name.utility';
import { userStore } from '~/stores/user.store';
// Props
export let region;
// Store Data
const { user: { language: userLanguage } } = userStore;
// Reactive Data
let displayName;
$: displayName = getLocalizedName(region.names, $userLanguage) || dashToCaps(region.name);
</script>

<style lang="scss">div.region-summary-container {
  max-width: 73.1428571429rem;
}
div.region-summary-container div.details {
  padding: 1rem;
}
div.region-summary-container div.details p.name {
  margin: 0;
  font-size: 1.2em;
  font-weight: 600;
}</style>
