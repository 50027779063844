<fieldset class="checkbox-list"><legend><slot name="legend">{ legend }</slot></legend><div class="instructions"><slot name="instructions"></slot></div><div class="options">{#each options as option }<label><input type="checkbox" name="{ name }" value="{ option.value }" bind:group="{ value }"><span>{ option.label }</span></label>{/each}</div></fieldset>

<script lang="ts">import { dashCase } from '~/utilities/string.utilities';
export let legend;
export let options;
export let value;
const name = dashCase(legend);
</script>

<style lang="scss">fieldset.checkbox-list {
  border: none;
  padding: 0;
}
fieldset.checkbox-list legend {
  text-transform: uppercase;
  font-weight: 500;
}
fieldset.checkbox-list div.options {
  border: 0.125rem solid var(--color-bg);
  border-radius: var(--default-border-radius);
  display: flex;
  flex-direction: column;
  padding: 1em;
}
fieldset.checkbox-list div.options label {
  cursor: pointer;
  margin: 0.75em 0;
  font-size: 0.85em;
  display: flex;
  align-items: center;
  gap: 1em;
}
fieldset.checkbox-list div.options label input[type=checkbox] {
  height: 2em;
  width: 2em;
}</style>
