<nav bind:this="{ element }"><ul><li><Link to="/pokedex"><SquaresFour aria-label="Pokédex"></SquaresFour></Link></li><li><Link to="/"><House aria-label="Home"></House></Link></li><li><Link to="/regions"><Compass aria-label="Regions"></Compass></Link></li></ul></nav>

<script lang="ts">import Compass from 'phosphor-svelte/lib/Compass';
import House from 'phosphor-svelte/lib/House';
import { Link } from 'svelte-routing';
import SquaresFour from 'phosphor-svelte/lib/SquaresFour';
// Props
export let element;
</script>

<style lang="scss">nav {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  background-color: var(--color-fg);
  z-index: 2;
  box-shadow: 0 0 2em 0.5em var(--color-theme);
}
nav ul {
  display: flex;
  justify-content: space-between;
  margin: 0;
}
nav ul li {
  flex-grow: 1;
  text-align: center;
  will-change: filter;
  transition: filter var(--default-animation-duration) ease-in-out;
}
nav ul li:hover {
  filter: brightness(2);
}
nav ul li:active {
  filter: brightness(0.2);
}
nav ul li :global(a) {
  color: var(--color-bg);
  display: block;
  padding: 1rem;
}
nav ul li :global(svg) {
  height: 2rem;
  width: 2rem;
  fill: var(--color-bg);
}</style>
