<DefaultLayout><article><h1>Regions</h1>{#await regionsPromise}<p>Loading...</p>{:then}
{#if regions.length > 0}<ul class="region-list">{#each regions as region (region.name)}<li><Link to="/regions/{ region.name }"><RegionSummary { region }></RegionSummary></Link></li>{/each}</ul>{:else}<p>None Found!</p>{/if}
{:catch error}<p>Error: { error.message }</p>{/await}</article></DefaultLayout>

<script lang="ts">import CardComponent from '~/components/layout/card.component.svelte';
import DefaultLayout from '~/layouts/default.layout.svelte';
import { Link } from 'svelte-routing';
import regionRepository from '~/services/repositories/region.repository';
import RegionSummary from '~/components/region-summary.component.svelte';
import { userStore } from '~/stores/user.store';
// Store Data
const { user: { language: userLanguage, versionGroup: userVersionGroup } } = userStore;
// Data
let regionsPromise;
let rawRegions = [];
let regions = [];
let regionNames = [];
$: regionNames = $userVersionGroup.regions.map(({ name }) => name);
$: regionsPromise = regionRepository.getAll(...regionNames);
$: {
    (async () => {
        rawRegions = await regionsPromise;
    })();
}
$: {
    regions = rawRegions
        .sort(({ id: idA }, { id: idB }) => {
        if (idA > idB)
            return 1;
        if (idA < idB)
            return -1;
        return 0;
    });
}
</script>

<style lang="scss">ul.region-list {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}
ul.region-list li {
  max-width: 73.1428571429rem;
  flex-grow: 1;
  flex-basis: 30rem;
}</style>
