<DefaultLayout hideHeader="{ true }"><h1>Settings</h1><CardComponent><form><SelectInput name="language" bind:options="{ languages }" bind:value="{ selectedLanguageId }"><span slot="label">Language</span></SelectInput></form></CardComponent></DefaultLayout>

<script lang="ts">import CardComponent from '~/components/layout/card.component.svelte';
import { dashToCaps } from '~/utilities/string.utilities';
import DefaultLayout from '~/layouts/default.layout.svelte';
import { getLocalizedName } from '~/utilities/pokeapi/get-localized-name.utility';
import languageRepository from '~/services/repositories/language.repository';
import { onMount } from 'svelte';
import SelectInput from '~/components/form/select.component.svelte';
import { userStore } from '~/stores/user.store';
// Store Data
const { user: { language: userLanguage, version: userVersion } } = userStore;
// Data
let selectedLanguageId = $userLanguage.id;
let languages = [];
let unsortedLanguages = [];
// Methods
const getLanguageLabel = (language) => {
    const localizedName = getLocalizedName(language.names, language);
    if (localizedName.length > 0)
        return localizedName;
    return dashToCaps(language.name);
};
// Reactive Data
$: {
    languages = unsortedLanguages
        .map(language => ({ label: getLanguageLabel(language), value: language.id }))
        .sort(({ label: labelA }, { label: labelB }) => labelA.localeCompare(labelB));
}
$: {
    if (selectedLanguageId !== $userLanguage.id)
        userStore.setLanguage(selectedLanguageId);
}
// Lifecycle Hooks
onMount(async () => {
    unsortedLanguages = await languageRepository.getAll();
});
</script>

<style lang="scss">form {
  padding: 1rem;
}</style>
