<svelte:window bind:scrollY="{ y }"></svelte:window><header bind:this="{ element }" use:styles="{ headerStyles }"><VersionSelectorComponent></VersionSelectorComponent><div class="profile-icon" bind:this="{ profileIconContainer }" use:styles="{ profileIconContainerStyles }"><Link to="/settings"><Gear aria-label="Settings"></Gear></Link></div></header>

<script lang="ts">import Gear from 'phosphor-svelte/lib/Gear';
import { Link } from 'svelte-routing';
import { styles } from '~/utilities/actions/styles.action';
import VersionSelectorComponent from '~/components/version-selector.component.svelte';
// Props
export let element;
// Data
const scrollPosMultiplier = 1;
let y = scrollY;
let lastScrollPosition = y;
let headerStyles = {
    translationAmount: 0
};
let profileIconContainer;
let profileIconContainerStyles = {};
// Reactive Hooks
$: {
    if (element) {
        const { clientHeight } = element;
        const { translationAmount: currentTranslationAmount } = headerStyles;
        let newTranslationAmount = currentTranslationAmount;
        if (y > lastScrollPosition) {
            const diff = y - lastScrollPosition;
            newTranslationAmount -= diff * scrollPosMultiplier;
        }
        else if (y < lastScrollPosition) {
            const diff = lastScrollPosition - y;
            newTranslationAmount += diff * scrollPosMultiplier;
        }
        if (newTranslationAmount > 0)
            newTranslationAmount = 0;
        else if (newTranslationAmount < -100)
            newTranslationAmount = -100;
        headerStyles = {
            translationAmount: newTranslationAmount
        };
        lastScrollPosition = y;
    }
}
$: {
    if (profileIconContainer)
        profileIconContainerStyles = {
            height: `${profileIconContainer.clientHeight}px`
        };
}
</script>

<style lang="scss">header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--color-theme);
  z-index: 2;
  box-shadow: 0 0 2em 0.5em var(--color-theme);
  height: 5rem;
  transform: translateY(calc(1% * var(--translationAmount)));
  will-change: transform;
  transition: transform var(--default-animation-duration) linear;
}
header div.profile-icon {
  padding: 0.25rem;
  display: flex;
  align-items: center;
}
header div.profile-icon :global(a) {
  color: currentColor;
}
header div.profile-icon :global(a:hover svg),
header div.profile-icon :global(a:active svg) {
  fill: var(--color-theme);
  background-color: var(--color-fg);
}
header div.profile-icon :global(svg) {
  --width: 2.4rem;
  width: var(--width);
  height: var(--width);
  border-radius: 50%;
  will-change: fill, background-color;
  transition: fill var(--default-animation-duration) linear, background-color var(--default-animation-duration) linear;
}</style>
