<div class="location-areas-list-container">{#await locationAreasPromise}<p>Loading...</p>{:then locationAreas}
{#if locationAreas.length > 0}<ul class="location-areas-list">{#each locationAreas as locationArea}<li><LocationAreaDetailComponent { locationArea }></LocationAreaDetailComponent></li>{/each}</ul>{:else}<p>This location has no visitable areas in the game you're currently playing.</p>{/if}
{:catch error}<p>{ error.message }</p>{/await}</div>

<script lang="ts">import LocationAreaDetailComponent from '~/components/location-area-detail.component.svelte';
import locationAreaRepository from '~/services/repositories/location-area.repository';
import { hasEncounterInVersion } from '~/utilities/pokeapi/has-encounter-in-version.utility';
import { userStore } from '~/stores/user.store';
// Props
export let locationAreaNames;
// Store Data
const { user: { version: userVersion } } = userStore;
// Methods
const getLocationAreas = async (...names) => {
    let allAreas = [];
    if (names.length > 0)
        allAreas = await locationAreaRepository.getAll(...names);
    return allAreas
        .filter(area => hasEncounterInVersion(area, $userVersion.name))
        .sort(({ id: idA }, { id: idB }) => {
        if (idA > idB)
            return 1;
        if (idA < idB)
            return -1;
        return 0;
    });
};
// Reactive Data
let locationAreasPromise;
$: locationAreasPromise = getLocationAreas(...locationAreaNames);
</script>

<style lang="scss">div.location-areas-list-container ul.location-areas-list {
  padding: 0;
  list-style-type: none;
}
div.location-areas-list-container ul.location-areas-list li {
  margin-bottom: 3rem;
}
div.location-areas-list-container ul.location-areas-list li:last-of-type {
  margin-bottom: 0;
}</style>
