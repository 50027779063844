<DefaultLayout><article><h1>Pokédex</h1><Card><form class="dex-selector"><Select name="Select a Pokédex" bind:value="{ selectedPokedexName }" bind:options="{ pokedexOptions }"></Select></form></Card>{#if selectedPokedex}<h2>{ selectedPokedexDisplayName } Dex</h2><ul class="pokemon-list">{#each pokemonEntries as pokemonEntry (`${selectedPokedexName}-${pokemonEntry.entry_number}`)}<li><PokemonEntry { pokemonEntry }></PokemonEntry></li>{/each}</ul>{/if}</article></DefaultLayout>

<script lang="ts">import Card from '~/components/layout/card.component.svelte';
import { dashToCaps } from '~/utilities/string.utilities';
import DefaultLayout from '~/layouts/default.layout.svelte';
import { getLocalizedName } from '~/utilities/pokeapi/get-localized-name.utility';
import Level from '~/components/layout/level.component.svelte';
import { NATIONAL_DEX_NAME } from '~/app.config';
import { onMount } from 'svelte';
import PokemonEntry from '~/components/pokemon-entry.component.svelte';
import pokedexRepository from '~/services/repositories/pokedex.repository';
import Select from '~/components/form/select.component.svelte';
import { userStore } from '~/stores/user.store';
// Store Data
const { user: { language: userLanguage, versionGroup: userVersionGroup } } = userStore;
// Data
let selectedPokedexName = $userVersionGroup ? $userVersionGroup.pokedexes[0].name : '';
let selectedPokedex;
let selectedPokedexDisplayName = '';
let nationalDex;
let regionalDexes = [];
let pokedexOptions = [];
let pokemonEntries = [];
// Methods
const displayName = (name, names, language) => {
    const localizedName = getLocalizedName(names, language);
    if (localizedName)
        return localizedName;
    return dashToCaps(name);
};
const fetchNationalDex = async () => {
    nationalDex = await pokedexRepository.get(NATIONAL_DEX_NAME);
};
const fetchRegionalDexes = async () => {
    const regionalDexNames = $userVersionGroup?.pokedexes.map(({ name }) => name);
    regionalDexes = await pokedexRepository.getAll(...regionalDexNames);
};
// Reactive Hooks
$: if ($userVersionGroup)
    fetchRegionalDexes();
$: {
    if (nationalDex)
        pokedexOptions = [nationalDex, ...regionalDexes]
            .map(({ name, names }) => {
            const label = displayName(name, names, $userLanguage);
            return { label, value: name };
        });
}
$: if (nationalDex)
    selectedPokedex = [nationalDex, ...regionalDexes].find(({ name }) => name === selectedPokedexName);
$: {
    if (selectedPokedex) {
        const { pokemon_entries, name, names } = selectedPokedex;
        pokemonEntries = [...pokemon_entries];
        selectedPokedexDisplayName = displayName(name, names, $userLanguage);
    }
}
// Lifcycle Hooks
onMount(async () => {
    nationalDex = await pokedexRepository.get(NATIONAL_DEX_NAME);
});
</script>

<style lang="scss">form.dex-selector {
  padding: 1rem;
}

ul.pokemon-list {
  list-style-type: none;
  padding: 0;
}
ul.pokemon-list li {
  margin-bottom: 1rem;
}
ul.pokemon-list li:last-of-type {
  margin-bottom: 0;
}</style>
