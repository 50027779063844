<Card>
	<div
		class="pokemon-encounter-container"
		class:caught="{ isCaught }"
		use:inview="{ {} }"
		on:change="{ inviewHandler }"
	>
		<div class="overlay">
			<CheckCircle />
			<!-- <p>Caught!</p> -->
		</div>
		{#if loading}
			<p class="loading">Loading...</p>
		{:else}
			<p class="heading">{ pokemonDisplayName }</p>
			<p class="genus-text">{ genusText }</p>
			<div class="sprite-container">
				<img src="{ pokemon.sprites.front_default }" alt="{ pokemonDisplayName }" />
			</div>
			<p class="flavor-text">
				{ flavorText }
			</p>
			{#if versionDetails}
				<div class="version-details">
					<EncounterDetailsListComponent encounterDetailsList="{ versionDetails.encounter_details }" />
				</div>
			{/if}
			<div class="capture-controls-container">
				<Button on:click="{ catchPokemon }" type="accent">
					<strong>Catch</strong>
				</Button>
				<!-- <button type="button" on:click="{ releasePokemon }">Release</button> -->
			</div>
		{/if}
	</div>
</Card>

<script lang="ts">import Button from '~/components/form/button.component.svelte';
import Card from '~/components/layout/card.component.svelte';
import CheckCircle from 'phosphor-svelte/lib/CheckCircle';
import debounce from 'debounce';
import EncounterDetailsListComponent from '~/components/encounter-details-list.component.svelte';
import { getLocalizedName } from '~/utilities/pokeapi/get-localized-name.utility';
import { inview } from 'svelte-inview/dist/index.mjs';
import pokemonRepository from '~/services/repositories/pokemon.repository';
import pokemonSpeciesRepository from '~/services/repositories/pokemon-species.repository';
import { time } from '~/utilities/time.utility';
import { userStore } from '~/stores/user.store';
// Props
export let encounter;
// Store Data
const { user: { language: userLanguage, pokemon: userPokemon, version: userVersion } } = userStore;
// Data
let isCaught = false;
// Lifcycle Hooks
let loading = true;
let pokemon;
let pokemonSpecies;
let pokemonDisplayName = '';
const onInView = async () => {
    if (!loading)
        return;
    pokemon = await pokemonRepository.get(encounter.pokemon.name);
    pokemonSpecies = await pokemonSpeciesRepository.get(pokemon.species.name);
    pokemonDisplayName = getLocalizedName(pokemonSpecies.names, $userLanguage);
    loading = false;
};
// Reactive Data
let flavorText = '';
let genusText = '';
let isInView = false;
let versionDetailsList;
let versionDetails;
let userHasPokemon = false;
$: if (isInView)
    onInView();
$: {
    if (pokemonSpecies) {
        pokemonDisplayName = getLocalizedName(pokemonSpecies.names, $userLanguage);
        const flavorTexts = pokemonSpecies.flavor_text_entries
            .filter(({ language: { name: languageName }, version: { name: versionName } }) => languageName === $userLanguage.name && versionName === $userVersion.name)
            .map(({ flavor_text }) => flavor_text);
        if (flavorTexts.length > 0)
            flavorText = flavorTexts.reduce(text => text);
        const genusTexts = pokemonSpecies.genera
            .filter(({ language: { name } }) => name === $userLanguage.name)
            .map(({ genus }) => genus);
        if (genusTexts.length > 0)
            genusText = genusTexts.reduce(genus => genus);
    }
}
$: {
    versionDetailsList = encounter.version_details
        .filter(({ version: { name } }) => name === $userVersion.name);
    if (versionDetailsList.length > 0)
        versionDetails = versionDetailsList[0];
    else
        versionDetails = null;
}
$: if (pokemon)
    userHasPokemon = $userPokemon.map(({ pokemon }) => pokemon).includes(pokemon.name);
// Methods
const catchPokemon = () => {
    if (!$userVersion)
        return;
    isCaught = true;
    setTimeout(() => {
        userStore.catchPokemon(pokemon.name, $userVersion.name);
    }, time(0.75).seconds);
};
const releasePokemon = () => {
    if ($userVersion)
        userStore.releasePokemon(pokemon.name, $userVersion.name);
};
const inviewHandler = debounce(({ detail: { inView } }) => {
    if (inView)
        isInView = inView;
}, 200);
</script>

<style lang="scss">div.pokemon-encounter-container {
  border-radius: 0.5rem;
  margin: 0.25rem;
  min-width: 10rem;
  width: calc(100% - 0.5rem);
  min-height: 16rem;
  height: calc(100% - 0.5rem);
  padding: 1rem;
  position: relative;
}
div.pokemon-encounter-container div.overlay {
  position: absolute;
  height: calc(100% + 2rem);
  width: calc(100% + 2rem);
  background-color: transparent;
  top: -1rem;
  left: -1rem;
  filter: opacity(0);
  pointer-events: none;
  will-change: background-color, filter;
  transition: filter 0.3s ease-in;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--color-lightest);
}
div.pokemon-encounter-container div.overlay :global(svg) {
  height: 50%;
  width: 50%;
  fill: currentColor;
  filter: brightness(1.75);
  transform: scale(10);
  will-change: transform;
  transition: transform 0.3s ease-in-out;
}
div.pokemon-encounter-container div.overlay p {
  margin: 0;
  font-size: 1.5em;
  font-weight: 500;
}
div.pokemon-encounter-container.caught div.overlay {
  background-color: var(--color-success);
  filter: opacity(1);
  pointer-events: all;
}
div.pokemon-encounter-container.caught div.overlay :global(svg) {
  transform: scale(1);
}
div.pokemon-encounter-container p.loading,
div.pokemon-encounter-container p.heading,
div.pokemon-encounter-container p.genus-text,
div.pokemon-encounter-container div.sprite-container {
  text-align: center;
}
div.pokemon-encounter-container p.loading {
  padding: 0.25rem 0.5rem;
}
div.pokemon-encounter-container p.heading {
  font-weight: 500;
  font-size: 1.25em;
  margin: 0;
}
div.pokemon-encounter-container p.genus-text {
  margin-top: 0;
  font-size: 0.8em;
}
div.pokemon-encounter-container div.version-details {
  margin-bottom: 1em;
}
div.pokemon-encounter-container div.capture-controls-container {
  display: flex;
  flex-direction: column;
}
div.pokemon-encounter-container div.capture-controls-container :global(button strong) {
  text-transform: uppercase;
}
div.pokemon-encounter-container div.capture-controls-container :global(button:active) {
  filter: brightness(0.8);
}</style>
