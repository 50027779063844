<div class="modal-drawer">{#if $activeModal}<div class="modal" transition:scale="{ transitionOptions }"><div class="component-wrapper"><svelte:component this="{ $activeModal }"></svelte:component></div><div class="close-button"><IconButton on:click="{ closeModal }"><X></X></IconButton></div></div>{/if}</div>

<script lang="ts">import { BODY_LOCKED_CLASS } from '~/globals/css-class.globals';
import IconButton from '~/components/form/icon-button.component.svelte';
import { scale } from 'svelte/transition';
import { uiStore } from '~/stores/ui.store';
import X from 'phosphor-svelte/lib/X';
// Store Data
const { ui: { activeModal } } = uiStore;
// Data
const transitionOptions = {
    delay: 0,
    duration: 250
};
// Methods
const closeModal = () => {
    uiStore.hideModal();
};
// Reactive Hooks
$: {
    if ($activeModal) {
        document.body.classList.add(BODY_LOCKED_CLASS);
    }
    else {
        document.body.classList.remove(BODY_LOCKED_CLASS);
    }
}
</script>

<style lang="scss">div.modal {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: var(--color-fg);
  color: var(--color-bg);
  z-index: 9;
  padding: 1rem;
}
div.modal div.component-wrapper {
  max-height: 100vh;
  overflow-y: auto;
  padding-bottom: 10rem;
}
div.modal div.close-button {
  background: linear-gradient(transparent, var(--color-fg));
  font-size: 2rem;
  position: fixed;
  bottom: 0;
  width: 100vw;
  display: flex;
  justify-content: center;
}</style>
