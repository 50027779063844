<form><p class="heading">Pokémon Encounter Filter Controls</p><Select name="Uncaught Pokémon" bind:value="{ showUncaughtValue }" options="{ showUncaughtOptions }"></Select><CheckboxList legend="Encounter Conditions" options="{ encounterConditionOptions }" bind:value="{ encounterConditionsValue }"><p slot="instructions">Select encounter conditions you'd like listed</p></CheckboxList><CheckboxList legend="Encounter Methods" options="{ encounterMethodOptions }" bind:value="{ encounterMethodsValue }"><p slot="instructions">Select encounter methods you'd like listed</p></CheckboxList></form>

<script lang="ts">import CheckboxList from '~/components/form/checkbox-list.component.svelte';
import { dashToCaps } from '~/utilities/string.utilities';
import encounterConditionValueRepository from '~/services/repositories/encounter-condition-value.repository';
import encounterMethodRepository from '~/services/repositories/encounter-method.repository';
import { getLocalizedName } from '~/utilities/pokeapi/get-localized-name.utility';
import { onMount } from 'svelte';
import Select from '~/components/form/select.component.svelte';
import { UIEncountersUncaughtOption } from '~/models/ui-encounters-uncaught-option.enum';
import { uiStore } from '~/stores/ui.store';
import { userStore } from '~/stores/user.store';
// Store Data
const { ui: { encounters: { filterConditions, filterMethods, showUncaught } } } = uiStore;
const { user: { language: userLanguage } } = userStore;
// Data
let showUncaughtValue = $showUncaught === undefined ? UIEncountersUncaughtOption.THIS_VERSION : $showUncaught;
const showUncaughtOptions = [
    { label: 'Show all', value: false },
    { label: 'Show those uncaught in this version', value: UIEncountersUncaughtOption.THIS_VERSION },
    { label: 'Show those uncaught in all versions', value: UIEncountersUncaughtOption.ALL_VERSIONS }
];
let encounterConditions = [];
let encounterConditionsValue = $filterConditions;
let encounterConditionOptions = [];
let encounterMethods = [];
let encounterMethodsValue = $filterMethods;
let encounterMethodOptions = [];
// Methods
const populateEncounterConditions = async () => {
    encounterConditions = await encounterConditionValueRepository.getAll();
};
const populateEncounterMethods = async () => {
    encounterMethods = await encounterMethodRepository.getAll();
};
// Lifecycle Hooks
onMount(() => {
    populateEncounterConditions();
    populateEncounterMethods();
});
// Reactive Hooks
$: showUncaught.set(showUncaughtValue);
$: if ($filterConditions !== encounterConditionsValue)
    filterConditions.set(encounterConditionsValue);
$: if ($filterMethods !== encounterMethodsValue)
    filterMethods.set(encounterMethodsValue);
$: {
    encounterConditionOptions = encounterConditions
        .filter(({ name }, i, arr) => {
        return arr.findIndex(condition => condition.name === name) === i;
    })
        .map(({ name, names }) => ({
        label: getLocalizedName(names, $userLanguage) || dashToCaps(name),
        value: name
    }))
        .sort(({ label: labelA }, { label: labelB }) => labelA.localeCompare(labelB));
}
$: {
    encounterMethodOptions = encounterMethods
        .filter(({ name }, i, arr) => {
        return arr.findIndex(method => method.name === name) === i;
    })
        .map(({ name, names }) => ({
        label: getLocalizedName(names, $userLanguage) || dashToCaps(name),
        value: name
    }))
        .sort(({ label: labelA }, { label: labelB }) => labelA.localeCompare(labelB));
}
</script>

<style lang="scss">form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
form p.heading {
  font-size: 1.5em;
  font-weight: 600;
  margin: 0;
  line-height: 1.6em;
}</style>
