{#if !hideHeader}<HeaderComponent bind:element="{ header }"></HeaderComponent>{/if}<main use:styles="{ mainStyles }"><slot></slot></main><NavComponent bind:element="{ nav }"></NavComponent>

<script lang="ts">import HeaderComponent from '~/components/layout/header.component.svelte';
import NavComponent from '~/components/layout/nav.component.svelte';
import { styles } from '~/utilities/actions/styles.action';
import { userStore } from '~/stores/user.store';
// Props
export let hideHeader = false;
// Reactive Data
let header;
let nav;
let mainStyles = {};
$: {
    const styles = {};
    if (header)
        styles['header-height'] = `${header.offsetHeight}px`;
    if (nav)
        styles['nav-height'] = `${nav.offsetHeight}px`;
    mainStyles = styles;
}
</script>

<style lang="scss">main {
  padding-top: var(--header-height);
  padding-bottom: var(--nav-height);
}</style>
