<button { type } on:click><slot></slot></button>

<script lang="ts">export let type = 'button';
</script>

<style lang="scss">button {
  border: none;
  cursor: pointer;
  border-radius: 100%;
  padding: 0.25em;
  margin: 0.25em;
  background-color: transparent;
  transition: background-color var(--default-animation-duration) ease-in-out, filter calc(var(--default-animation-duration) / 3) ease-in-out;
  will-change: background-color, filter;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
button:hover {
  background-color: currentColor;
}
button:hover :global(svg) {
  filter: invert(100%) grayscale(100%);
}
button:active {
  filter: opacity(0.9);
}
button :global(svg) {
  height: 1em;
  width: 1em;
  fill: currentColor;
  will-change: filter;
  transition: filter var(--default-animation-duration) ease-in-out;
}</style>
