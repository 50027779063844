<div class="encounter-details-list-container"><table><caption>Encounters</caption><thead><tr><th class="method">Method</th><th class="conditions">Conditions</th><th class="chance">Chance</th></tr></thead><tbody>{#each filteredEncounterDetails as encounterDetails}<EncounterDetailsComponent { encounterDetails }></EncounterDetailsComponent>{/each}</tbody></table></div>

<script lang="ts">import { byUnique } from '~/utilities/array/filter.utilities';
import EncounterDetailsComponent from '~/components/encounter-details.component.svelte';
import { uiStore } from '~/stores/ui.store';
// Props
export let encounterDetailsList;
// Store Data
const { ui: { encounters: { filterConditions, filterMethods } } } = uiStore;
// Data
let filteredEncounterDetails = [];
// Reactive Hooks
$: {
    filteredEncounterDetails = encounterDetailsList
        .filter(details => {
        if (!$filterMethods.includes(details.method.name))
            return false;
        if (!details.condition_values.every(({ name }) => $filterConditions.includes(name)))
            return false;
        return true;
    })
        .sort(({ chance: chanceA, chance: chanceB }) => {
        if (chanceA > chanceB)
            return 1;
        if (chanceA < chanceB)
            return -1;
        return 0;
    })
        .filter((encounterDetails, i, arr) => {
        const parentCvNames = encounterDetails.condition_values.map(({ name: cvName }) => cvName);
        const matchIndex = arr.findIndex(({ method: { name: methodName }, condition_values: conditionValues }) => {
            if (methodName !== encounterDetails.method.name)
                return false;
            if (conditionValues.length !== encounterDetails.condition_values.length)
                return false;
            const allCvsMatch = conditionValues.every(({ name }) => parentCvNames.includes(name));
            return allCvsMatch;
        });
        return matchIndex === i;
    });
}
</script>

<style lang="scss">div.encounter-details-list-container table {
  --border-style: 0.0625rem solid var(--color-dark);
  width: 100%;
  font-size: 0.8em;
}
div.encounter-details-list-container table caption {
  text-align: left;
  font-weight: 500;
  border-bottom: var(--border-style);
}
div.encounter-details-list-container table thead {
  border-bottom: var(--border-style);
}
div.encounter-details-list-container table thead tr th {
  font-weight: 300;
  font-size: 0.8em;
}
div.encounter-details-list-container table thead tr th.conditions, div.encounter-details-list-container table thead tr th.chance {
  text-align: center;
}
div.encounter-details-list-container table thead tr th.chance {
  width: 7ch;
}</style>
