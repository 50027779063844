<ModalDrawer></ModalDrawer><div class="application-wrapper"><Router url="{ url }"><Route path="/" component="{ HomePage }"></Route><Route path="/pokedex" component="{ PokedexPage }"></Route><Route path="/regions" component="{ RegionListPage }"></Route><Route path="/regions/:regionName" component="{ RegionDetailPage }"></Route><Route path="/regions/:regionName/:locationName" component="{ LocationDetailPage }"></Route><Route path="/settings" component="{ SettingsPage }"></Route></Router></div>

<script lang="ts">import HomePage from '~/pages/home.page.svelte';
import LocationDetailPage from '~/pages/location-detail.page.svelte';
import ModalDrawer from '~/components/layout/modal-drawer.component.svelte';
import PokedexPage from '~/pages/pokedex.page.svelte';
import SettingsPage from '~/pages/settings.page.svelte';
import RegionDetailPage from '~/pages/region-detail.page.svelte';
import RegionListPage from '~/pages/region-list.page.svelte';
import { userStore } from '~/stores/user.store';
import { Route, Router } from 'svelte-routing';
// Props
export let url = '';
// Store Data
const { user: { version: userVersion } } = userStore;
// Data
const versionClassPrefix = 'version-';
$: {
    const { body } = document;
    const versionClass = `${versionClassPrefix}${$userVersion.name}`;
    if (body.className.includes(versionClassPrefix)) {
        body.className = body.className
            .split(' ')
            .map(className => {
            if (!className.includes(versionClassPrefix))
                return className;
            return versionClass;
        })
            .join(' ');
    }
    else {
        body.classList.add(versionClass);
    }
}
</script>
