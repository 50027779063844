<div class="location-area-container">{#if locationAreaDisplayName }<p class="heading">Area: { locationAreaDisplayName }</p>{/if}<PokemonEncounterListComponent { encounters }></PokemonEncounterListComponent></div>

<script lang="ts">import Card from '~/components/layout/card.component.svelte';
import { getLocalizedName } from '~/utilities/pokeapi/get-localized-name.utility';
import PokemonEncounterListComponent from '~/components/pokemon-encounter-list.component.svelte';
import { userStore } from '~/stores/user.store';
// Props
export let locationArea;
// Store Data
const { user: { language: userLanguage } } = userStore;
// Data
const { pokemon_encounters: encounters } = locationArea;
// Reactive Data
let locationAreaDisplayName;
$: locationAreaDisplayName = getLocalizedName(locationArea.names, $userLanguage);
</script>

<style lang="scss">div.location-area-container p.heading {
  font-size: 0.9rem;
  font-weight: 500;
  text-transform: uppercase;
}</style>
