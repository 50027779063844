<div class="select"><label for="{ name }"><slot name="label">{ name }</slot></label><div class="instructions"><slot name="instructions"></slot></div><select bind:value>{#each options as option}<option value="{ option.value }">{ option.label }</option>{/each}</select></div>

<script lang="ts">export let name;
export let options;
export let value;
</script>

<style lang="scss">div.select {
  display: flex;
  flex-direction: column;
}
div.select label {
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 0.5em;
}
div.select select {
  padding: 1em;
  border-radius: var(--default-border-radius);
  border-color: var(--color-bg);
  cursor: pointer;
}</style>
