<svelte:window on:click="{ windowClickHandler }"></svelte:window><svelte:body bind:this="{ body }"></svelte:body><div class="version-selector"><button class="current-version" type="button" on:click="{ toggleMenu }" class:active="{ menuExpanded }" bind:this="{ menuButton }"><span>Pokémon { getDisplayName($userVersion.names, $userVersion.name) }</span><CaretDown></CaretDown></button>{#if menuExpanded}<ul class="version-list" bind:this="{ menu }" class:active="{ menuExpanded }" transition:fade="{ transitionOptions }" use:styles="{ menuStyles }">{#each versions as version}<li class="version" on:click="{ selectVersion(version.name) }">{ getDisplayName(version.names, version.name) }</li>{/each}</ul>{/if}</div>

<script lang="ts">import CaretDown from 'phosphor-svelte/lib/CaretDown';
import { dashToCaps } from '~/utilities/string.utilities';
import { fade } from 'svelte/transition';
import { DEFAULT_ANIMATION_DURATION } from '~/app.config';
import { getLocalizedName } from '~/utilities/pokeapi/get-localized-name.utility';
import { onMount } from 'svelte';
import { styles } from '~/utilities/actions/styles.action';
import { targetWithinElement } from '~/utilities/element.utilities';
import { userStore } from '~/stores/user.store';
import versionRepository from '~/services/repositories/version.repository';
// Store Data
const { user: { language: userLanguage, version: userVersion } } = userStore;
// Data
let body;
let menu;
let menuExpanded = false;
let menuButton;
let menuStyles = {};
const transitionOptions = {
    delay: 25,
    duration: DEFAULT_ANIMATION_DURATION * 0.75
};
// Methods
const getDisplayName = (names, name) => {
    const localizedName = getLocalizedName(names, $userLanguage);
    if (!localizedName)
        return dashToCaps(name);
    return localizedName;
};
const updateMenuStyles = () => {
    if (menuButton)
        menuStyles = { 'button-width': `${menuButton.clientWidth}px` };
};
const selectVersion = (versionName) => {
    userStore.setVersion(versionName);
    menuExpanded = false;
};
const toggleMenu = () => {
    if (!menuExpanded)
        updateMenuStyles();
    menuExpanded = !menuExpanded;
};
const windowClickHandler = ({ target }) => {
    if (target === null)
        return;
    if (!menuExpanded)
        return;
    if (targetWithinElement(target, menu))
        return;
    if (targetWithinElement(target, menuButton))
        return;
    toggleMenu();
};
// Reactive Hooks
$: if (body)
    menuExpanded ? body.classList.add('locked') : body.classList.remove('locked');
// Lifecycle Hooks
let versions = [];
onMount(async () => {
    const unsortedVersions = await versionRepository.getAll();
    versions = unsortedVersions
        .sort(({ id: idA }, { id: idB }) => {
        if (idA > idB)
            return 1;
        if (idA < idB)
            return -1;
        return 0;
    });
    updateMenuStyles();
});
</script>

<style lang="scss">div.version-selector {
  display: inline-block;
  font-size: 1.1rem;
}
div.version-selector button.current-version,
div.version-selector ul.version-list {
  --padding: 0.5em;
  --border: 0.125em solid currentColor;
  border-radius: 0.5em;
  cursor: pointer;
}
div.version-selector button.current-version {
  border: var(--border);
  border-color: var(--color-fg);
  padding: var(--padding);
  transition: background-color var(--default-animation-duration) linear;
  will-change: background-color;
  background-color: var(--color-theme);
  display: inline-block;
}
div.version-selector button.current-version:hover, div.version-selector button.current-version:active, div.version-selector button.current-version.active {
  background-color: var(--color-fg);
  color: var(--color-bg);
}
div.version-selector button.current-version :global(svg) {
  margin-left: var(--padding);
}
div.version-selector ul.version-list {
  --button-width: 13rem;
  background-color: var(--color-fg);
  color: var(--color-bg);
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin-top: 1em;
  max-height: 65vh;
  overflow: auto;
  border: var(--border);
  border-color: var(--color-fg);
  position: absolute;
  top: 3.5rem;
  left: 1rem;
  width: var(--button-width);
  box-shadow: 0 1.4rem 2.7rem rgba(0, 0, 0, 0.3), 0 1rem 0.9rem rgba(0, 0, 0, 0.22);
}
div.version-selector ul.version-list li {
  padding: 1em var(--padding);
  border-bottom: 0.125rem solid var(--color-bg);
}
div.version-selector ul.version-list li:hover {
  background-color: var(--color-bg);
  color: var(--color-fg);
}
div.version-selector ul.version-list li:last-of-type {
  border-bottom: none;
}</style>
