{#if versionEncounters.length <= 0}<p>This area has no Pokémon</p>{:else if filteredEncounters.length <= 0}<p>There are no Pokémon in this area which you need to catch</p>{:else}<ul class="pokemon-encounter-list">{#each filteredEncounters as encounter (encounter.pokemon.name)}<li out:slide="{ {} }"><PokemonEncounterComponent { encounter }></PokemonEncounterComponent></li>{/each}</ul>{/if}

<script lang="ts">import PokemonEncounterComponent from '~/components/pokemon-encounter-detail.component.svelte';
import { slide } from 'svelte/transition';
import { UIEncountersUncaughtOption } from '~/models/ui-encounters-uncaught-option.enum';
import { uiStore } from '~/stores/ui.store';
import { userStore } from '~/stores/user.store';
// Props
export let encounters;
// Store Data
const { ui: { encounters: { filterConditions, filterMethods, showUncaught } } } = uiStore;
const { user: { pokemon: userPokemon, version: userVersion } } = userStore;
// Reactive Data
let versionEncounters = [];
let filteredEncounters = [];
$: {
    versionEncounters = encounters
        .filter(encounter => {
        const encounterPresentInUserVersion = encounter.version_details
            .map(({ version: { name } }) => name)
            .includes($userVersion.name);
        if (!encounterPresentInUserVersion)
            return false;
        return true;
    });
}
$: {
    filteredEncounters = versionEncounters
        .filter(encounter => {
        if ($showUncaught === UIEncountersUncaughtOption.THIS_VERSION) {
            const userHasCaughtEncounteredPokemon = $userPokemon
                .filter(({ version }) => version === $userVersion.name)
                .map(({ pokemon }) => pokemon)
                .includes(encounter.pokemon.name);
            if (userHasCaughtEncounteredPokemon)
                return false;
        }
        else if ($showUncaught === UIEncountersUncaughtOption.ALL_VERSIONS) {
            const userHasCaughtEncounteredPokemon = $userPokemon
                .map(({ pokemon }) => pokemon)
                .includes(encounter.pokemon.name);
            if (userHasCaughtEncounteredPokemon)
                return false;
        }
        const encounterIncludesMethodsSelectedByUser = encounter.version_details
            .filter(({ version: { name } }) => name === $userVersion.name)
            .map(({ encounter_details }) => encounter_details)
            .flat(1)
            .map(({ method: { name } }) => name)
            .some(name => $filterMethods.includes(name));
        if (!encounterIncludesMethodsSelectedByUser)
            return false;
        const encounterIncludesConditionsSelectedByUser = encounter.version_details
            .filter(({ version: { name } }) => name === $userVersion.name)
            .map(({ encounter_details }) => encounter_details)
            .flat(1)
            .filter(({ condition_values }) => {
            return condition_values.every(({ name }) => $filterConditions.includes(name));
        })
            .length > 0;
        if (!encounterIncludesConditionsSelectedByUser)
            return false;
        return true;
    });
}
</script>

<style lang="scss">ul.pokemon-encounter-list {
  list-style-type: none;
  padding: 0;
  columns: 25rem auto;
  column-gap: 1rem;
}
ul.pokemon-encounter-list li {
  margin-bottom: 1rem;
}</style>
