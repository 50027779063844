<Card><div class="details"><p class="name">{ displayName }</p></div></Card>

<script lang="ts">import Card from '~/components/layout/card.component.svelte';
import { dashToCaps } from '~/utilities/string.utilities';
import { getLocalizedName } from '~/utilities/pokeapi/get-localized-name.utility';
import { getNameSubtitle } from '~/utilities/pokeapi/get-name-subtitle.utility';
import { userStore } from '~/stores/user.store';
// Props
export let location;
// Store Data
const { user: { language: userLanguage } } = userStore;
// Reactive Data
let displayName;
$: {
    let localizedName = getLocalizedName(location.names, $userLanguage);
    if (localizedName.length <= 0)
        displayName = dashToCaps(location.name);
    const subtitle = getNameSubtitle(location.name);
    if (subtitle === null)
        displayName = localizedName;
    else
        displayName = `${localizedName} - ${subtitle}`;
}
</script>

<style lang="scss">div.details {
  padding: 1rem;
}
div.details p.name {
  font-size: 1.5em;
  margin: 0.5em 0;
}</style>
