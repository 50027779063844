<Card><div class="caught-pokemon-summary-container" use:inview="{ {} }" on:change="{ inviewHandler }">{#if loading}<p class="loading">Loading...</p>{:else}<p class="heading">{ pokemonSpeciesDisplayName }</p><div class="sprite-container"><img src="{ pokemon.sprites.front_default }" alt="{ pokemonSpeciesDisplayName }"></div>{#if includeVersion}<p class="version">Pokémon { versionDisplayName }</p>{/if}
{#if location}<p class="location">{ locationDisplayName }</p>{/if}<div class="capture-controls-container"><Button on:click="{ releasePokemon }" type="warn">Release</Button></div>{/if}</div></Card>

<script lang="ts">import Button from '~/components/form/button.component.svelte';
import Card from '~/components/layout/card.component.svelte';
import { dashToCaps } from '~/utilities/string.utilities';
import debounce from 'debounce';
import { getLocalizedName } from '~/utilities/pokeapi/get-localized-name.utility';
import { inview } from 'svelte-inview/dist/index.mjs';
import locationRepository from '~/services/repositories/location.repository';
import pokemonRepository from '~/services/repositories/pokemon.repository';
import pokemonSpeciesRepository from '~/services/repositories/pokemon-species.repository';
import { time } from '~/utilities/time.utility';
import { userStore } from '~/stores/user.store';
import versionRepository from '~/services/repositories/version.repository';
// Props
export let caughtPokemon;
export let includeVersion = true;
// Store Data
const { user: { language: userLanguage } } = userStore;
// Data
let isInView = false;
let loading = true;
let location;
let locationDisplayName = 'Unknown';
let pokemon;
let pokemonSpecies;
let pokemonSpeciesDisplayName = 'Unknown';
let version;
let versionDisplayName = 'Unknown';
// Methods
const inviewHandler = debounce(({ detail: { inView } }) => {
    if (inView)
        isInView = inView;
}, time(200).milliseconds);
const onInView = async () => {
    if (caughtPokemon.location)
        location = await locationRepository.get(caughtPokemon.location);
    pokemon = await pokemonRepository.get(caughtPokemon.pokemon);
    pokemonSpecies = await pokemonSpeciesRepository.get(pokemon.species.name);
    version = await versionRepository.get(caughtPokemon.version);
    loading = false;
};
const releasePokemon = () => userStore.releasePokemon(caughtPokemon.pokemon, caughtPokemon.version);
// Reactive Hooks
$: if (isInView)
    onInView();
$: if (pokemonSpecies)
    pokemonSpeciesDisplayName = getLocalizedName(pokemonSpecies.names, $userLanguage) || dashToCaps(pokemonSpecies.name);
$: if (version)
    versionDisplayName = getLocalizedName(version.names, $userLanguage) || dashToCaps(version.name);
$: if (location)
    locationDisplayName = getLocalizedName(location.names, $userLanguage) || dashToCaps(location.name);
</script>

<style lang="scss">div.caught-pokemon-summary-container {
  padding: 1rem;
  min-height: 16rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
div.caught-pokemon-summary-container p.version,
div.caught-pokemon-summary-container p.location {
  margin: 0;
}
div.caught-pokemon-summary-container p.version:last-of-type,
div.caught-pokemon-summary-container p.location:last-of-type {
  margin-bottom: 1em;
}
div.caught-pokemon-summary-container div.capture-controls-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}
div.caught-pokemon-summary-container div.capture-controls-container :global(button) {
  flex-grow: 1;
}</style>
