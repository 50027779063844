<tr class="encounter-details">{#if loading}<td colspan="3"><p class="loading">Loading...</p></td>{:else}<td class="method">{ encounterMethodName }</td><td class="conditions">{#if encounterDetails.condition_values.length > 0}<ul class="condition-value-list">{#each encounterDetails.condition_values as cv}<li><ConditionValueDetail name="{ cv.name }"></ConditionValueDetail></li>{/each}</ul>{:else}<p class="centered">-</p>{/if}</td><td class="chance">{ encounterDetails.chance}%</td>{/if}</tr>

<script lang="ts">import ConditionValueDetail from '~/components/condition-value-detail.component.svelte';
import { dashToCaps } from '~/utilities/string.utilities';
import encounterMethodRepository from '~/services/repositories/encounter-method.repository';
import { getLocalizedName } from '~/utilities/pokeapi/get-localized-name.utility';
import { onMount } from 'svelte';
import { userStore } from '~/stores/user.store';
// Store Data
const { user: { language: userLanguage } } = userStore;
// Props
export let encounterDetails;
// Data
let loading = true;
let encounterMethod = null;
// Computed
let encounterMethodName = dashToCaps(encounterDetails.method.name);
// Methods
const displayName = (name, names, language) => {
    const localizedName = getLocalizedName(names, language);
    if (localizedName)
        return localizedName;
    return dashToCaps(name);
};
// Lifecycle Hooks
onMount(async () => {
    encounterMethod = await encounterMethodRepository.get(encounterDetails.method.name);
    loading = false;
});
// Reactive Hooks
$: if (encounterMethod)
    encounterMethodName = displayName(encounterMethod.name, encounterMethod.names, $userLanguage);
</script>

<style lang="scss">tr td {
  padding-top: 1em;
  padding-right: 0.5em;
  vertical-align: top;
}
tr td:last-of-type {
  padding-right: 0;
}
tr td.conditions, tr td.chance {
  text-align: center;
}
tr td.conditions p {
  margin: 0;
}
tr td ul.condition-value-list {
  list-style-type: none;
  padding: 0;
}
tr:first-of-type td {
  padding-top: 0;
}</style>
