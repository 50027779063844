<div class="encounter-condition-value-detail">{#if loading}<p class="loading">Loading...</p>{:else}<p>{ displayName }</p>{/if}</div>

<script lang="ts">import { dashToCaps } from '~/utilities/string.utilities';
import encounterConditionValueRepository from '~/services/repositories/encounter-condition-value.repository';
import { getLocalizedName } from '~/utilities/pokeapi/get-localized-name.utility';
import { onMount } from 'svelte';
import { userStore } from '~/stores/user.store';
// Props
export let name;
// Store Data
const { user: { language: userLanguage } } = userStore;
// Data
let loading = true;
let encounterConditionValue;
let displayName = dashToCaps(name);
// Reactive Hooks
$: if (encounterConditionValue)
    displayName = getLocalizedName(encounterConditionValue.names, $userLanguage) || dashToCaps(name);
// Lifecycle Hooks
onMount(async () => {
    encounterConditionValue = await encounterConditionValueRepository.get(name);
    loading = false;
});
</script>

<style lang="scss">div.encounter-condition-value-detail p {
  margin: 0;
}</style>
