<DefaultLayout><article>{#await regionPromise}<p>Loading...</p>{:then region}<h1>The { regionDisplayName } Region</h1><div class="locations-container"><h2>Locations</h2>{#await locationsPromise}<p>Loading...</p>{:then}
{#if locations.length > 0}<ul class="location-list">{#each locations as location (location.name)}<li><Link to="/regions/{ regionName }/{ location.name }"><LocationSummary { location }></LocationSummary></Link></li>{/each}</ul>{:else}<p>Hmm, it doesn't look like there are any locations in this region.</p><p>Perhaps the archives are incomplete?</p>{/if}
{:catch error}<p>{ error.message }</p>{/await}</div>{:catch error}<p>{ error.message }</p>{/await}</article></DefaultLayout>

<script lang="ts">import DefaultLayout from '~/layouts/default.layout.svelte';
import { getLocalizedName } from '~/utilities/pokeapi/get-localized-name.utility';
import { Link } from 'svelte-routing';
import locationRepository from '~/services/repositories/location.repository';
import LocationSummary from '~/components/location-summary.component.svelte';
import { onMount } from 'svelte';
import regionRepository from '~/services/repositories/region.repository';
import { userStore } from '~/stores/user.store';
// Props
export let regionName;
// Store Data
const { user: { language: userLanguage } } = userStore;
// Data
const regionPromise = regionRepository.get(regionName);
const locationsPromise = regionPromise.then(({ locations }) => {
    const locationNames = locations.map(({ name }) => name);
    if (locationNames.length > 0)
        return locationRepository.getAll(...locationNames);
    return [];
});
// Lifecycle Hooks
let rawLocations = [];
let locations = [];
let region;
onMount(async () => {
    region = await regionPromise;
    rawLocations = await locationsPromise;
});
// Reactive Data
let regionDisplayName = '';
$: if (region)
    regionDisplayName = getLocalizedName(region.names, $userLanguage.name);
$: {
    locations = rawLocations.sort(({ names: aNames }, { names: bNames }) => {
        const aName = getLocalizedName(aNames, $userLanguage.name);
        const bName = getLocalizedName(bNames, $userLanguage.name);
        return aName.localeCompare(bName, undefined, { numeric: true });
    });
}
</script>

<style lang="scss">ul.location-list {
  list-style-type: none;
  padding: 0;
}
ul.location-list li {
  margin-bottom: 1rem;
}
ul.location-list li:last-of-type {
  margin-bottom: 0;
}
ul.location-list li :global(a) {
  color: var(--color-bg);
}</style>
